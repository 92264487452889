.app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    overflow: hidden;
}

.app-main {
    padding: 4rem 1rem;
}

.app-footer {
    font-size: 0.875rem;
    padding: 1rem 0;
    text-align: center;

    span {
        display: block;
    }
}
