.card-form {
    margin: 0 auto;
    max-width: 43.75rem;

    .card-header {
        display: flex;
        padding-bottom: 0;
        text-align: center;

        .row {
            align-items: center;
        }
    }

    .card-title {
        margin-top: 0.5rem;
    }

    .card-footer {
        display: flex;
        padding-bottom: 1rem;
        padding-top: 1rem;
        justify-content: flex-end;
    }
}
