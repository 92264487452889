@import '~bootstrap/scss/functions';

$st: #02334c;
$nd: #E42123;

$st-dark: darken($st, 10%);
$nd-dark: darken($nd, 10%);

$fb: #4267b2;
$go: #db4437;

$primary: $st;
$secondary: $nd;

$colors: (
    primary: $st,
    secondary: $nd,
);

$border-radius: 1rem;
