.form-group {
    margin-top: 1rem;
}

.form-label {
    margin-bottom: 0;
}

.mask-card {
    background-size: contain;
    background-position: right top;
    background-repeat: no-repeat;
}
